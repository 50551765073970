class InvestorsContact {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.mapEl = document.querySelector('[data-investors-contact-map]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.googleMapInstance = null;
        this.markers = [];

        return true;
    }

    init() {
        this.initGoogleMap();
    }

    async initGoogleMap() {
        if (!this.mapEl) return;

        let position = {
            lat: 52.390887,
            lng: 16.8608581,
        };

        if (this.settings.lat && this.settings.lon) {
            position = {
                lat: parseFloat(this.settings.lat),
                lng: parseFloat(this.settings.lon),
            };
        }

        const { Map } = await google.maps.importLibrary('maps');

        this.googleMapInstance = new Map(this.mapEl, {
            zoom: 14,
            center: position,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clickableIcons: false,
        });

        this.setInitMarker(position);
    }

    setInitMarker(position) {
        const marker = new google.maps.Marker({
            position,
            map: this.googleMapInstance,
            icon: {
                url: this.settings.marker_url,
                scaledSize: new google.maps.Size(26, 33),
            },
        });

        this.markers.push(marker);
    }
}

function initInvestorsContact() {
    const sections = document.querySelectorAll('[data-investors-contact]');
    if (!sections) return;

    sections.forEach(item => new InvestorsContact(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initInvestorsContact();
} else {
    document.addEventListener('DOMContentLoaded', initInvestorsContact);
}
